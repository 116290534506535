
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Watch} from 'vue-property-decorator';
    import {Route, NavigationGuardNext} from 'vue-router';
    import {namespace} from 'vuex-class';
    import {
            IAgenda, 
            IVeterinarian, 
            IUser, 
            IEducation
    } from '@/types';
    import {
        bannerHelper,
    } from '@/helpers';

    import BookingWidget from '@/components/BookingWidget.vue';
    import Breadcrumbs from '@/components/Breadcrumbs.vue';
    import OrganizationAddress from '@/components/organization/OrganizationAddress.vue';
    import OrganizationPricing from '@/components/organization/OrganizationPricing.vue';
    import OrganizationOpening from '@/components/organization/OrganizationOpening.vue';
    import VeterinarianInfos from '@/components/veterinarian/VeterinarianInfos.vue';
    import OrganizationNewsCard from '@/components/OrganizationNewsCard.vue';

    const accountNamespace = namespace('account');

    @Component<ViewVeterinarian>({
        components: {
            BookingWidget,
            Breadcrumbs,
            OrganizationAddress,
            OrganizationOpening,
            OrganizationPricing,
            VeterinarianInfos,
            OrganizationNewsCard,
        },
        beforeRouteEnter(to: Route, from: Route, next: any) {
            const type = to.params.type;
            const city = to.params.city;
            const organizationSlug = to.params.organizationSlug;
            const veterinarianSlug = to.params.veterinarianSlug;

            (Vue.prototype as Vue).$api.agenda
                .getBySlug(`${type}/${city}/${organizationSlug}/${veterinarianSlug}`)
                .then((veterinarian: IVeterinarian) => {
                    next((vm: ViewVeterinarian) => vm.veterinarian = veterinarian);
                })
            ;
        },
        beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
            document.documentElement.style.overflow = 'auto';
            next();
        },
    })
    export default class ViewVeterinarian extends Vue {
        public showMap: boolean =  false;
        public showBookAppointment: boolean = false;
        public bookingWidgetActive: boolean = false;
        public windowScroll!: number;
        public isScrolled: boolean = false;
        public veterinarian: IVeterinarian|null = null;
        public openDialog: boolean = false;
        public dynaban: string = '';

        @accountNamespace.Getter('loggedUser')
        public loggedUser!: IUser;

        get currentSlug(): string {
            const type = this.$route.params.type;
            const city = this.$route.params.city;
            const organizationSlug = this.$route.params.organizationSlug;
            const veterinarianSlug = this.$route.params.veterinarianSlug;

            return `${type}/${city}/${organizationSlug}/${veterinarianSlug}`;
        }

        get agenda() {
            if (!this.veterinarian?.agendas) {
                return null;
            }

            return (this.veterinarian.agendas as IAgenda[]).find((agenda: IAgenda) => {
                return agenda.slug === this.currentSlug;
            });
        }

        public getEducationLine(education: IEducation): string {
            const parts = [];

            if (education.school) {
                parts.push(education.school);
            }

            if (education.year) {
                parts.push(education.year);
            }

            return parts.join(' - ');
        }

        public addWindowScrollListener() {
            window.addEventListener('scroll', () => {
                this.windowScroll = window.scrollY;

                const orgamenu: HTMLElement = this.$refs.organizationMenu as HTMLElement;

                if (orgamenu !== undefined) {
                    this.$nextTick(() => {
                        if ((orgamenu).offsetTop < this.windowScroll) {
                            this.isScrolled = true;
                        }

                        if (this.windowScroll < 330) {
                            this.isScrolled = false;
                        }
                    });
                }

            });
        }

        public mounted() {
            this.addWindowScrollListener();
        }

        public created() {
            this.dynaban = bannerHelper.getDynaBan();
        }

        @Watch('showMap')
        public onShowMapChange(val: boolean) {
            document.documentElement.style.overflow = val ? 'hidden' : 'auto';
        }

        @Watch('showBookAppointment')
        public onShowBookAppointmentChange(val: boolean) {
            document.documentElement.style.overflow = val ? 'hidden' : 'auto';
        }
    }
