
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';

    import {
        IVeterinarian,
    } from '@/types';

    @Component<VeterinarianPlaces>({
        components: {
        },
    })
    export default class VeterinarianPlaces extends Vue {
        @Prop({
            type: Object,
            required: false,
        })
        public veterinarian !: IVeterinarian;

        get currentSlug(): string {
            const type = this.$route.params.type;
            const city = this.$route.params.city;
            const organizationSlug = this.$route.params.organizationSlug;
            const veterinarianSlug = this.$route.params.veterinarianSlug;

            return `${type}/${city}/${organizationSlug}/${veterinarianSlug}`;
        }
    }
