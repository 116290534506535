
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';
    import VeterinarianEducation from '@/components/veterinarian/VeterinarianEducation.vue';
    import VeterinarianPlaces from '@/components/veterinarian/VeterinarianPlaces.vue';
    import OrganizationThumbs from '@/components/organization/OrganizationThumbs.vue';

    import {
        IVeterinarian,
    } from '@/types';

    @Component<VeterinarianInfos>({
        components: {
            VeterinarianEducation,
            VeterinarianPlaces,
            OrganizationThumbs,
        },
    })
    export default class VeterinarianInfos extends Vue {
        public showed: boolean = false;

        @Prop({
            type: Object,
            required: false,
        })
        public veterinarian !: IVeterinarian;

        get isTooBig() {
            if (!this.veterinarian.presentation) {
                return;
            }

            return this.veterinarian.presentation.length > 270;
        }

        get presentation() {
          if (!this.isTooBig || this.showed) {
            return this.veterinarian.presentation;
          }

          return `${this.veterinarian.presentation?.slice(0, 270)}...`;
        }

        public handleShowMore() {
          this.showed = !this.showed;
        }
    }
